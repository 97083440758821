.EintragPraefixIcon img {
  width: 40px;
  height: 60px;
  object-fit: scale-down;
}

.EintragPraefixIcon svg {
  width: 40px;
  height: 60px;
}
