.Vz {
  max-height: 100%;
  box-sizing: border-box;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
}

.Vz .Title {
  flex-basis: auto;
  flex-shrink: 0;
  font-size: 15pt;
  line-height: 1.4em;
  font-weight: bold;
}

.Vz .Content {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  overflow: hidden;
  display: flex;
  font-size: 12pt;
}

.Vz .Content .Description {
  overflow: auto;
  box-sizing: border-box;
}

.Vz .Content .Image {
}

.Vz .Content .Image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Vz .Content .Image svg {
  width: 100%;
  height: 100%;
}

@media (orientation: landscape) {
  .Vz .Content {
    flex-direction: row-reverse;
  }

  .Vz .Content .Description {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: center;
    margin-left: 0.5em;
  }

  .Vz .Content .Image {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 0;
  }

  .Vz .Content .Image img {
  }
}

@media (orientation: portrait) {
  .Vz .Content {
    flex-direction: column;
  }

  .Vz .Content .Description {
    flex-basis: auto;
    flex-shrink: 0;
    margin: 0.5em 0;
  }

  .Vz .Content .Image {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  .Vz .Content .Image img {
  }
}
