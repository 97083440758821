@font-face {
  font-family: "BundesSansWeb";
  src: local(BundesSansWeb-Regular),
    url("../public/Ressourcen/Fonts/BundesSansWeb-Regular.woff2")
      format("woff2"),
    url("../public/Ressourcen/Fonts/BundesSansWeb-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BundesSansWeb";
  src: local(BundesSansWeb-Bold),
    url("../public/Ressourcen/Fonts/BundesSansWeb-Bold.woff2") format("woff2"),
    url("../public/Ressourcen/Fonts/BundesSansWeb-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "BundesSansWeb";
  src: local(BundesSansWeb-Italic),
    url("../public/Ressourcen/Fonts/BundesSansWeb-Italic.woff2") format("woff2"),
    url("../public/Ressourcen/Fonts/BundesSansWeb-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "BundesSansWeb";
  src: local(BundesSansWeb-BoldItalic),
    url("../public/Ressourcen/Fonts/BundesSansWeb-BoldItalic.woff2")
      format("woff2"),
    url("../public/Ressourcen/Fonts/BundesSansWeb-BoldItalic.woff")
      format("woff");
  font-weight: bold;
  font-style: italic;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

:root {
  --elwis-green: #589199;
}

:root:root {
  --adm-color-primary: var(--elwis-green);
  --adm-outline-font-size: 1.5rem;
  --adm-font-family: BundesSansWeb, Calibri, Verdana, Arial, Helvetica,
    sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#appViewPort {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

#appMain {
  position: relative;
  display: flex;
  flex: auto;
  overflow: hidden;
}

#appContent {
  flex: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

#appContent .enable {
  pointer-events: auto;
}

#appContent .disable {
  pointer-events: none;
  /*opacity: 0.6;
  filter: grayscale(100%);*/
}

#appContent .header {
  padding: 0.5ex;
}

#appContent .header .versionInfo {
  font-style: italic;
}

#appContent .content {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.5ex;
}

#appMenu {
  position: absolute;
  right: 0;
  max-width: 70%;
  min-height: 100%;
  z-index: 999;
  padding: 0.5ex;
  transition: transform 0.7s cubic-bezier(0, 0.52, 0, 1);
  background-color: white;
  border-left: 1px var(--elwis-green) solid;
}

#appMenu.hide {
  transform: translate3d(+100%, 0, 0);
}

#appMenu.show {
  transform: translate3d(+0%, 0, 0);
}

#appMenu .version {
  position: absolute;
  bottom: 1ex;
  width: 95%;
  text-align: right;
  color: lightgray;
}

#appFooter {
  border-top: 1px #eee solid;
}

.adm-tab-bar-wrap {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  min-height: 48px;
}
.adm-tab-bar-item {
  flex: 1;
  color: var(--adm-color-text-secondary);
  white-space: nowrap;
  padding: 4px 8px;
  width: -webkit-min-content;
  width: min-content;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.adm-tab-bar-item-icon {
  font-size: 24px;
  height: 24px;
  line-height: 1;
}
.adm-tab-bar-item-title {
  font-size: var(--adm-font-size-2);
  line-height: 15px;
}
.adm-tab-bar-item-title-with-icon {
  margin-top: 2px;
}
.adm-tab-bar-item-active {
  color: var(--adm-color-primary);
}
.adm-tab-bar-icon-badge {
  --top: 6px;
}
.adm-tab-bar-title-badge {
  --right: -2px;
  --top: -2px;
}
